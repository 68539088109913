import App from './App.svelte';

const app1 = new App({
    target: document.querySelector('#feedback_form1'),
    props: {
        f_type_p: '1',
        mini_p: true,
    }
});

export default app1;